import React from "react";
import renderHTML from "../helpers/renderHTML";

const List = ({ name, description, items }) => {
  return (
    <div>
      <h3 className="govuk-heading-s">{name}</h3>
      <div className="govuk-body-s">{renderHTML(description)}</div>
      {items &&
        items.length > 0 &&
        items.map((item, itemIndex) => (
          <div key={`${name}_${itemIndex}`}>
            <h3 className="govuk-heading-s">{item.name}</h3>
            <p className="govuk-body-s">{item.value}</p>
          </div>
        ))}
    </div>
  );
};

export default List;
