import React, { useEffect } from "react";
import styles from "./WebServicesPage.module.scss";
import renderHTML from "../helpers/renderHTML";
import WebServices from "../components/WebServices";
import Licence from "../components/Licence";

const WebServicesPage = ({ data }) => {
  const {
    source,
    name,
    description,
    licences,
    keywords,
    updateNote,
    webservices,
  } = data;

  useEffect(() => {
    document.title = name + " | Dáta SAŽP" || "Dáta SAŽP";
  }, [name]);

  return (
    <div className={styles.webServicesPage}>
      <header className={styles.header}>
        <h1 className="govuk-heading-l">{source.name}</h1>
        <h2 className="govuk-body-l">{name}</h2>
        <div className="govuk-body-s">{renderHTML(description)}</div>
      </header>

      {/* A section for metadata */}
      <main>
        <div className={styles.metadata}>
          <div className={styles.title}>
            <div></div>
            <h2>Metadata</h2>
          </div>
          <div className={styles.info}>
            <div className={styles.row}>
              <p className={styles.subtitle}>Podmienky prístupu a použitia</p>
              <div className={styles.licensesWrapper}>
                {/* Render each license using the License component */}
                {licences &&
                  licences.length > 0 &&
                  licences.map((license) => (
                    <section className={styles.licence} key={license.seoId}>
                      <Licence license={license} />
                    </section>
                  ))}
              </div>
            </div>
            <div className={styles.row}>
              <p className={styles.subtitle}>Tags</p>
              <div className={styles.tags}>
                {Object.values(keywords)?.map((tag) => (
                  <p className={styles.tag} key={tag.seoId}>
                    {tag.name}
                  </p>
                ))}
              </div>
            </div>
            <div className={styles.row}>
              <p className={styles.subtitle}>Stav aktualizácie</p>
              <div className="govuk-body-s">{renderHTML(updateNote)}</div>
            </div>
          </div>
        </div>
      </main>

      {/* Render the web services using the WebServices component */}
      <section>
        <WebServices data={webservices} />
      </section>
    </div>
  );
};

export default WebServicesPage;
