import React from "react";
import Accordion from "./Accordion";
import List from "./List";

const Dataset = ({ name, description, serviceType, items, index }) => {
  switch (serviceType) {
    case "accordion":
      return (
        <div key={`dataset_${index}`}>
          <Accordion
            index={index}
            title={name}
            description={description}
            data={items}
          />
        </div>
      );
    case "list":
      return (
        <div key={`dataset_${index}`}>
          <List name={name} description={description} items={items} />
        </div>
      );
    default:
      return null;
  }
};

export default Dataset;
