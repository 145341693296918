import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./SearchPanel.module.scss";
import {
  setSelectedKeywordsSlice,
  setSelectedProtocolsSlice,
} from "../store/slices/appSlice";

const SearchPanel = ({ keywordOptions, protocolOptions }) => {
  const dispatch = useDispatch();
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [selectedProtocols, setSelectedProtocols] = useState([]);

  useEffect(() => {
    dispatch(setSelectedKeywordsSlice(selectedKeywords));
    dispatch(setSelectedProtocolsSlice(selectedProtocols));
  }, [selectedKeywords, selectedProtocols, dispatch]);

  return (
    <aside className={styles["search-panel-wrapper"]}>
      <h2>Filtre</h2>
      <div></div>
      <div data-module="idsk-search-results-filter">
        <div className="idsk-search-results__filter idsk-search-results-filter__filter-panel">
          <div className="idsk-search-results__link-panel idsk-search-results--expand idsk-search-results__content-type-filter">
            <button className="idsk-search-results__link-panel-button ">
              <span className="idsk-search-results__link-panel__title">
                Klucove slova
              </span>
              {/* <span className="idsk-search-results__link-panel--span"></span> */}
              <span
                className="idsk-search-results__link-panel--span"
                style={{ display: "none" }}
              ></span>
            </button>
            <div className="idsk-search-results__list">
              {keywordOptions &&
                keywordOptions.length > 0 &&
                keywordOptions.map((item) => {
                  return (
                    <div className="idsk-option-select-filter " key={item}>
                      <div className="govuk-form-group">
                        <div className="govuk-checkboxes govuk-checkboxes--small">
                          <div className="govuk-checkboxes__item">
                            <input
                              className="govuk-checkboxes__input"
                              id={item}
                              style={{ width: "20px", height: "20px" }}
                              name={item}
                              type="checkbox"
                              value={item}
                              checked={selectedKeywords.includes(item)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedKeywords([
                                    ...selectedKeywords,
                                    item,
                                  ]);
                                } else {
                                  setSelectedKeywords(
                                    selectedKeywords.filter(
                                      (selected) => selected !== item
                                    )
                                  );
                                }
                              }}
                            />

                            <label
                              className="govuk-label govuk-checkboxes__label"
                              htmlFor={item}
                            >
                              <div
                                className={styles["pocet-vysledkov-wrapper"]}
                              >
                                {item}
                                <span
                                  className={styles["pocet-vysledkov"]}
                                ></span>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div data-module="idsk-search-results-filter">
        <div className="idsk-search-results__filter idsk-search-results-filter__filter-panel">
          <div className="idsk-search-results__link-panel idsk-search-results--expand idsk-search-results__content-type-filter">
            <button className="idsk-search-results__link-panel-button ">
              <span className="idsk-search-results__link-panel__title">
                Protokoly
              </span>
              <span
                className="idsk-search-results__link-panel--span"
                style={{ display: "none" }}
              ></span>
            </button>
            <div className="idsk-search-results__list">
              {protocolOptions &&
                protocolOptions.length > 0 &&
                protocolOptions.map((item) => {
                  return (
                    <div className="idsk-option-select-filter " key={item}>
                      <div className="govuk-form-group">
                        <div className="govuk-checkboxes govuk-checkboxes--small">
                          <div className="govuk-checkboxes__item">
                            <input
                              className="govuk-checkboxes__input"
                              id={item}
                              name={item}
                              type="checkbox"
                              value={item}
                              checked={selectedProtocols.includes(item)}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedProtocols([
                                    ...selectedProtocols,
                                    item,
                                  ]);
                                } else {
                                  setSelectedProtocols(
                                    selectedProtocols.filter(
                                      (selected) => selected !== item
                                    )
                                  );
                                }
                              }}
                            />
                            <label
                              className="govuk-label govuk-checkboxes__label"
                              htmlFor={item}
                            >
                              <div
                                className={styles["pocet-vysledkov-wrapper"]}
                              >
                                {item}
                                <span
                                  className={styles["pocet-vysledkov"]}
                                ></span>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default SearchPanel;
