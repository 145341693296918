import React from "react";
import { Route, Routes } from "react-router-dom";
import Page from "../pages/Page";
import SearchPage from "../pages/SearchPage";
import HomePage from "../pages/HomePage";
import AccessPage from "../pages/AccessPage";
import ContactPage from "../pages/ContactPage";
import styles from "./App.module.scss";

const App = () => {
  return (
    <div className={styles["wrapper"]}>
      <Routes>
        <Route path="vyhladavanie" element={<SearchPage />} />
        <Route path="vyhlasenie-o-pristupnosti" element={<AccessPage />} />
        <Route path="kontakt" element={<ContactPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<Page />} />
      </Routes>
    </div>
  );
};

export default App;
