import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiNodeUrlApi } from "../../common/config";

const PAGE_URL = apiNodeUrlApi;

export const getPageData = createAsyncThunk(
  "pageSlice/getPageData",
  async (seoID) => {
    try {
      const response = await axios.get(PAGE_URL + seoID);
      return response.data;
    } catch (error) {
      console.log("Error: ", error);
      throw new Error(error.response.status);
    }
  }
);

const initialState = {
  data: {},
  loading: false,
  error: "",
  errorFetchingData: false,
  query: "",
  ready: false,
};

const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPageData.pending, (state) => {
        state.loading = true;
        state.ready = false;
        state.errorFetchingData = false;
      })
      .addCase(getPageData.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.ready = true;
        state.data = payload;
        state.isSuccess = true;
      })
      .addCase(getPageData.rejected, (state, { error }) => {
        state.error = error.message;
        state.loading = false;
        state.ready = false;
        state.errorFetchingData = true;
      });
  },
});

export const pageData = (state) => state.page.data;
export const errorFetchingData = (state) => state.page.errorFetchingData;
export const error = (state) => state.page.error;
export const ready = (state) => state.page.ready;

export default pageSlice.reducer;
