import React from "react";
import WebService from "./WebService";
import styles from "./WebServices.module.scss";

function WebServices({ data }) {
  const webServices = Object.values(data);

  return (
    <section className={styles["webservices-wrapper"]}>
      <div className={styles["title-blue"]}>
        <div></div>
        <h2>Zoznam webových služieb</h2>
      </div>
      {webServices?.length > 0 ? (
        webServices?.map((webService, index) => (
          <WebService key={`webservice_${index}`} webService={webService} />
        ))
      ) : (
        <p>Žiadne webové služby.</p>
      )}
    </section>
  );
}

export default WebServices;
