import React from "react";
import Source from "./Source";
import styles from "./ListOfSources.module.scss";
import { sourcesData, ready } from "../store/slices/appSlice";
import { useSelector } from "react-redux";

const ListOfSources = () => {
  const sources = useSelector(sourcesData);
  const isReady = useSelector(ready);

  return sources && isReady ? (
    <>
      <h2>Zoznam služieb:</h2>
      <div className={styles["list-of-sources"]}>
        {Object.keys(sources)?.map((key) => {
          return <Source data={sources[key]} key={sources[key].seoId} />;
        })}
      </div>
    </>
  ) : null;
};

export default ListOfSources;
