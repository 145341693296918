import React from "react";
import renderHTML from "../helpers/renderHTML";
import styles from "./Accordion.module.scss";

const Accordion = ({ index, title, description, data }) => {
  return (
    <div
      className="govuk-accordion"
      data-module="idsk-accordion"
      id={index}
      data-attribute="value"
    >
      <div className="govuk-accordion__controls">
        <button
          className="govuk-accordion__open-all"
          data-open-title="Otvoriť všetky"
          data-close-title="Zatvoriť všetky"
          type="button"
          aria-expanded="false"
          style={{ display: "none" }}
        >
          <span
            className="govuk-visually-hidden govuk-accordion__controls-span"
            data-section-title="sekcie"
          ></span>
        </button>
      </div>
      <div className="govuk-accordion__section ">
        <div className="govuk-accordion__section-header">
          <h2 className="govuk-accordion__section-heading">
            <span
              className="govuk-accordion__section-button"
              id={index + "-heading-1"}
            >
              {title}
            </span>
          </h2>
        </div>
        <div
          id={index + "-heading-1"}
          className="govuk-accordion__section-content"
          aria-labelledby="2-heading-1"
        >
          <div className={styles["content"]}>
            <div className="govuk-body-s">{renderHTML(description)}</div>
            <div>
              {Object.entries(data)?.map(([key, { name, value }]) => (
                <div key={`${name}_${key}`}>
                  <h3>{name}</h3>
                  <p>{value}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
