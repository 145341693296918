import React, { useEffect, useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { AiFillHome } from "react-icons/ai";
import styles from "./Breadcrumbs.module.scss";
import { useLocation } from "react-router-dom";
import { pageData } from "../store/slices/pageSlice";
import { useSelector } from "react-redux";

const Breadcrumbs = () => {
  const [level, setLevel] = useState(0);
  const data = useSelector(pageData);

  const location = useLocation();

  useEffect(() => {
    const url = location.pathname.substring(1);
    const parts = url.split("/");

    if (parts.length === 1) {
      setLevel(1);
    }

    if (parts.length === 2) {
      setLevel(2);
    }
  }, [location.pathname, data]);

  return (
    <div className={styles["breadcrumbs"]}>
      <a className="govuk-link" href="/">
        <div className={styles["icon-home"]}>
          <AiFillHome />
        </div>
        Domov
      </a>
      {level === 1 ? (
        <>
          <div className={styles["icon"]}>
            <MdArrowForwardIos />
          </div>
          <p className="govuk-body-s">{data.name}</p>
        </>
      ) : null}
      {level === 2 ? (
        <>
          <div className={styles["icon"]}>
            <MdArrowForwardIos />
          </div>
          <a className="govuk-link" href={"/" + data.source.seoId}>
            {data.source.name}
          </a>
          <div className={styles["icon"]}>
            <MdArrowForwardIos />
          </div>
          <p className="govuk-body-s">{data.name}</p>
        </>
      ) : null}
    </div>
  );
};

export default Breadcrumbs;
