import React, { useEffect } from "react";
import styles from "./SourcePage.module.scss";
import renderHTML from "../helpers/renderHTML";
import Service from "../components/Service";

const SourcePage = ({ data }) => {
  useEffect(() => {
    document.title = data.name + " | Dáta SAŽP" || "Dáta SAŽP";
  }, [data.name]);

  return (
    <div className={styles["source-page"]}>
      <h1 className="govuk-heading-l">{data.name}</h1>
      <div className="govuk-body">{renderHTML(data.description)}</div>
      <h2 className="govuk-heading-m">
        Zoznam komunikačných protokolov a štandardov používaných pre zdieľanie
        údajov
      </h2>
      {data?.protocols &&
        data?.protocols?.length > 0 &&
        data?.protocols?.map((protocol) => {
          return <Service data={protocol} key={protocol.seoId} />;
        })}
    </div>
  );
};

export default SourcePage;
