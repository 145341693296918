import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./slices/appSlice";
import pageReducer from "./slices/pageSlice";

const store = configureStore({
  reducer: {
    app: appReducer,
    page: pageReducer,
  },
});

export default store;
