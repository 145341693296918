import React from "react";
import styles from "./Source.module.scss";
import { HiOutlinePhone } from "react-icons/hi";
import { HiOutlineMail } from "react-icons/hi";
import { HiOutlineHome } from "react-icons/hi";
import renderHTML from "../helpers/renderHTML";
import { imgUrl } from "../common/config";

let altTitle = {
  "informacny-system-environmentalnych-zatazi-is-ez":
    "Ilustračný obrázok environmentálnej záťaže",
  "informacny-system-integrovanej-prevencie-a-kontroly-znecistovania-is-ipkz":
    "Ilustračný obrázok IPKZ",
  "informacny-system-nakladania-s-tazobnym-odpadom-is-nto":
    "Ilustračný obrázok IS NTO",
  "informacny-system-posudzovania-vplyvov-na-zivotne-prostredie-is-eia-sea":
    "Ilustračný obrázok IS EIA",
  "informacny-system-prevencie-zavaznych-priemyselnych-havarii":
    "Ilustračný obrázok IS PZPH",
};

const Source = ({ data }) => {
  return data ? (
    <div className={styles["sluzba-karta"]}>
      <div className="idsk-card idsk-card-secondary-horizontal">
        <div className={styles["card-top"]}>
          <a href={data.seoId}>
            {data.icons ? (
              <img
                className="idsk-card-img"
                src={imgUrl + data.icons["480"]}
                alt={altTitle[data.seoId] || data.name}
                aria-hidden="true"
              />
            ) : null}
          </a>

          <div>
            <div className={styles["info-topright"]}>
              <div className="idsk-heading idsk-heading-secondary-horizontal">
                <a
                  href={data.seoId}
                  className="govuk-link govuk-link--no-visited-state govuk-heading-s"
                  title="Informačný systém prevencie závažných priemyselných havárií"
                >
                  {data.name}
                </a>
              </div>
              <div className={styles["info-url-link"]}>
                <b>URL Služby: </b>{" "}
                <span className="govuk-body-s">
                  <a href={data.link} className="govuk-link">
                    {data.link}
                  </a>
                </span>
              </div>
              <div className={styles["text-top-right"]}>
                {renderHTML(data.description)}
              </div>
            </div>
          </div>
        </div>
        <section className={styles["section-wrapper"]}>
          <h4 className="govuk-heading-s">Kľúčové slová: </h4>
          <div className={styles["klucove-slova"]}>
            {data.keywords &&
              data.keywords.length > 0 &&
              data.keywords.map((keyword) => {
                return <p key={keyword.seoId}>{keyword.name}</p>;
              })}
          </div>
        </section>
        <section className={styles["kontakt-wrapper"]}>
          <h4 className="govuk-heading-s">
            Správca obsahu a technický prevádzkovateľ:
          </h4>
          {data.contacts &&
            data.contacts.length > 0 &&
            data.contacts.map(
              ({ title, organization, address, phone, email, link }, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className={styles["kontakt-right"]}>
                      <p className={styles["kontakt-right-title"]}>{title}</p>
                      <p className={styles["kontakt-right-subtitle"]}>
                        {organization}
                      </p>
                      <p className={styles["kontakt-line"]}>{address}</p>
                      <div className={styles["kontakt-line"]}>
                        <HiOutlinePhone />
                        <p>{phone}</p>
                      </div>
                      <div className={styles["kontakt-line"]}>
                        <HiOutlineMail />
                        <p>{email}</p>
                      </div>
                      <div className={styles["kontakt-line"]}>
                        <HiOutlineHome />
                        <p>{link}</p>
                      </div>
                    </div>
                  </React.Fragment>
                );
              }
            )}
        </section>
        <section className={styles["section-wrapper"]}>
          <h4 className="govuk-heading-s">Protokoly 111: </h4>
          <div className={styles["protokoly"]}>
            {data.protocols &&
              data.protocols.length > 0 &&
              data.protocols.map(({ seoId, icons, name }) => {
                const href = `${data.seoId}/${seoId}`;
                return (
                  <a
                    className={styles["protokoly-btn"]}
                    key={seoId}
                    href={href}
                  >
                    <img src={`${imgUrl}${icons["50"]}`} alt="#" />
                    <p>{name}</p>
                  </a>
                );
              })}
          </div>
        </section>
      </div>
    </div>
  ) : null;
};

export default Source;
