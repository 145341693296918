import React, { useEffect } from "react";
import ListOfSources from "../components/ListOfSources";
import { getSources, ready } from "../store/slices/appSlice";
import { useDispatch, useSelector } from "react-redux";
import styles from "./HomePage.module.scss";
import Hlavicka from "../components/Hlavicka";
import Footer from "../components/Footer";

let fisrtStart = true;

const HomePage = () => {
  const dispatch = useDispatch();
  const isReady = useSelector(ready);

  useEffect(() => {
    if (fisrtStart) {
      dispatch(getSources());
    }
    return () => {
      fisrtStart = false;
    };
  }, [dispatch]);

  return isReady ? (
    <>
      <Hlavicka title={"DÁTA SAŽP"} subtitle={"Portál otvorených dát"} />
      <div className="govuk-width-container">
        <div className={styles["page"]}>
          <p>
            Webové sídlo slúži na podporu poskytovania a sprístupňovania
            informácií z agendových informačných systémov verejnej správy,
            environmentálnych registrov a databáz, ktoré prevádzkuje{" "}
            <a href="https://www.sazp.sk">
              Slovenská agentúra životného prostredia
            </a>{" "}
            (SAŽP).
          </p>
          <p>
            Ponúka prehľad o dostupných popisných (atribútových) alebo
            priestorových údajoch, ktoré SAŽP sprístupňuje v štruktúrovanej
            forme, umožňujúcej ich hromadné strojové spracovanie. Väčšinu
            poskytovaných údajov je možné začleniť do kategórie{" "}
            <a href="https://data.slovensko.sk/">otvorených údajov</a>, ktoré sú
            voľne a bezplatne dostupné pre každého za rovnakých podmienok a
            ktoré je možné použiť na akýkoľvek účel komerčného či nekomerčného
            charakteru.
          </p>
          <p>Pre jednotlivé sprístupňované údaje sú k dispozícii:</p>
          <ul>
            <li>
              informácie o komunikačných protokoloch a štandardoch použitých pre
              ich zdieľanie (SOAP API, OGC Open Web Services - W3S, ArcGIS REST
              API, INSPIRE ATOM...),
            </li>
            <li>
              informácie o webových službách (metaúdaje, lokátor zdroja –
              endpoint...) publikujúcich datasety,
            </li>
            <li>zoznamy publikovaných datasetov,</li>
            <li>podmienky prístupu a použitia,</li>
            <li>stav aktualizácie.</li>
          </ul>
          <ListOfSources />
        </div>
      </div>
      <Footer />
    </>
  ) : null;
};

export default HomePage;
