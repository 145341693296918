import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiNodeUrlApi } from "../../common/config";

const SOURCES_URL = apiNodeUrlApi + "/sources?page=1";

export const getSources = createAsyncThunk("appSlice/getSources", async () => {
  
  
  try {
    const response = await axios.get(SOURCES_URL);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
  
});

const initialState = {
  sources: [],
  keywordOptions: [],
  selectedKeywords: [],
  protocolOptions: [],
  selectedProtocols: [],
  searchTerm: "",
  loading: false,
  error: "",
  query: "",
  ready: false,
  rejected: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSelectedKeywordsSlice(state, { payload }) {
      state.selectedKeywords = payload;
    },
    setSelectedProtocolsSlice(state, { payload }) {
      state.selectedProtocols = payload;
    },
    setUniqueKeywords(state, { payload }) {
      const keywords = [];
      Object.keys(payload).forEach((key) => {
        payload[key].keywords.forEach((keyword) => {
          keywords.push(keyword.name);
        });
      });
      state.keywordOptions = [...new Set(keywords)];
    },
    setUniqueProtocols(state, { payload }) {
      const protocols = [];
      Object.keys(payload).forEach((key) => {
        payload[key].protocols.forEach((protocol) => {
          protocols.push(protocol.name);
        });
      });
      state.protocolOptions = [...new Set(protocols)];
    },
    setSearchTermStr(state, { payload }) {
      state.searchTerm = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSources.pending, (state) => {
        state.loading = true;
        state.ready = false;
        state.rejected = false;
      })
      .addCase(getSources.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.sources = payload["hydra:member"];
        state.ready = true;
        state.isSuccess = true;
      })
      .addCase(getSources.rejected, (state, { payload }) => {
        state.error = payload;
        state.loading = false;
        state.rejected = true;
        state.ready = false;
      });
  },
});

export const sourcesData = (state) => state.app.sources;
export const rejected = (state) => state.app.rejected;
export const ready = (state) => state.app.ready;
export const searchTermStr = (state) => state.app.searchTerm;
export const keywordOptionsData = (state) => state.app.keywordOptions;
export const selectedKeywordsData = (state) => state.app.selectedKeywords;
export const protocolOptionsData = (state) => state.app.protocolOptions;
export const selectedProtocolsData = (state) => state.app.selectedProtocols;
export const readyData = (state) => state.app.ready;

export const {
  setSelectedKeywordsSlice,
  setSelectedProtocolsSlice,
  setUniqueKeywords,
  setUniqueProtocols,
  setSearchTermStr,
} = appSlice.actions;

export default appSlice.reducer;
