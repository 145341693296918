import React, { useEffect } from "react";
import styles from "./Footer.module.scss";

const Footer = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (!window.GOVUKFrontend.isInitialized) {
        window.GOVUKFrontend.initAll();
        window.GOVUKFrontend.isInitialized = true;
      }
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={styles["footer"]}>
      <div data-module="idsk-footer-extended">
        <footer className="idsk-footer-extended  ">
          <div className="idsk-footer-extended-feedback">
            <div className="govuk-width-container">
              <div
                id="idsk-footer-extended-feedback"
                className="idsk-footer-extended-feedback-text"
              >
                <div className="idsk-footer-extended-feedback-container">
                  <div id="idsk-footer-extended-info-question">
                    <div className="govuk-grid-column-two-thirds idsk-footer-extended-usefull-question">
                      <span className="idsk-footer-extended-feedback-question-info-usefull">
                        Boli tieto informácie pre vás užitočné?
                      </span>
                      <span className="idsk-footer-extended-usefull-question-mobile">
                        Je táto stránka užitočná?
                      </span>
                      <div className="idsk-footer-extended-usefull-answers-mobile">
                        <div>
                          <button
                            id="idsk-footer-extended-feedback-yes-button"
                            type="button"
                            className="idsk-footer-extended-feedback-text-answers idsk-footer-extended-help-button"
                          >
                            Áno
                            <span className="govuk-visually-hidden">
                              Boli tieto informácie pre vás užitočné?
                            </span>
                          </button>
                          <button
                            id="idsk-footer-extended-feedback-no-button"
                            type="button"
                            className="idsk-footer-extended-feedback-text-answers idsk-footer-extended-help-button"
                          >
                            Nie
                            <span className="govuk-visually-hidden">
                              Boli tieto informácie pre vás užitočné?
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="govuk-grid-column-one-third idsk-footer-extended-usefull-question idsk-footer-extended-white-border">
                      <div className="idsk-footer-extended-feedback-question-info-usefull">
                        <button
                          id="idsk-footer-extended-error-button"
                          type="button"
                          className="idsk-footer-extended-feedback-text-answers idsk-footer-extended-help-button"
                        >
                          Našli ste na stránke chybu?
                        </button>
                      </div>
                      <div className="idsk-footer-extended-usefull-question-mobile ">
                        <span>Našli ste na stránke chybu?</span>
                        <button
                          id="idsk-footer-extended-write-us-button"
                          type="button"
                          className="idsk-footer-extended-write-us-button idsk-footer-extended-feedback-text-answers idsk-footer-extended-help-button"
                        >
                          Napíšte nám
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    id="idsk-footer-extended-heart"
                    className="idsk-footer-extended-heart govuk-grid-column-full"
                  >
                    Ďakujeme za Vašu spätnú väzbu
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="idsk-footer-extended-feedback-content">
            <div className="govuk-width-container">
              <div className="govuk-grid-row">
                <div
                  id="idsk-footer-extended-help-form"
                  className="idsk-footer-extended-feedback-hidden idsk-footer-extended-display-hidden"
                >
                  <div
                    id="idsk-help-container"
                    className="idsk-footer-extended-feedback-container"
                  >
                    <div className="govuk-grid-column-full">
                      <div className="idsk-footer-extended-feedback-question-container">
                        <div className="govuk-grid-column-two-thirds idsk-footer-extended-help-form-header-mobile">
                          <h3 className="govuk-heading-x">
                            Pomôžte nám zlepšiť data.sazp.sk
                          </h3>
                        </div>
                        <div className="govuk-grid-column-one-third idsk-footer-extended-close-button-mobile">
                          <button
                            id="idsk-footer-extended-close-help-form-button"
                            type="button"
                            className="idsk-footer-extended-feedback-button idsk-footer-extended-help-button"
                          >
                            Zatvoriť
                          </button>
                        </div>
                      </div>
                      <p className="idsk-footer-extended-form-text">
                        Aby sme vedeli zlepšiť obsah na tejto stránke, chceli by
                        sme vedieť o Vašej skúsenosti so stránkou.
                      </p>
                      <br />
                      <div className="idsk-footer-extended-feedback-button">
                        <a
                          href="mailto:spatnavazba@sazp.sk?subject=ApiNode spätná väzba"
                          id="fill-feedback-help-form"
                          title="Vyplniť prieskum"
                          role="button"
                          className="idsk-button"
                          data-module="idsk-button"
                        >
                          Dať spätnú väzbu
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="idsk-footer-extended-error-form"
                  className="idsk-footer-extended-feedback-hidden idsk-footer-extended-display-hidden"
                >
                  <div className="idsk-footer-extended-feedback-container ">
                    <div className="govuk-grid-column-full">
                      <div className="idsk-footer-extended-feedback-question-container">
                        <div className="govuk-grid-column-two-thirds idsk-footer-extended-help-form-header-mobile">
                          <h3 className="govuk-heading-x">
                            Pomôžte nám zlepšiť data.sazp.sk{" "}
                          </h3>
                        </div>
                        <div className="govuk-grid-column-one-third idsk-footer-extended-close-button-mobile">
                          <button
                            id="idsk-footer-extended-close-error-form-button"
                            type="button"
                            className="idsk-footer-extended-feedback-button idsk-footer-extended-help-button"
                          >
                            Zatvoriť
                          </button>
                        </div>
                      </div>
                      <p className="idsk-footer-extended-form-text">
                        Prosím nevpisujte žiadne osobné informácie (meno,
                        kontakt a iné).
                      </p>
                      <br />
                      <div className="idsk-footer-extended-close-button">
                        <div className="govuk-form-group">
                          <label className="govuk-label" for="sort">
                            Aký typ chyby ste našli?
                          </label>
                          <select
                            className="govuk-select"
                            id="sort"
                            name="sort"
                          >
                            <option value="published">Validačná chyba</option>
                            <option value="updated" selected>
                              Gramatická chyba
                            </option>
                            <option value="views">Chyba responzivity</option>
                            <option value="comments">Chyba iná</option>
                          </select>
                        </div>
                      </div>
                      <div>
                        <br />

                        <div
                          className="idsk-character-count"
                          data-module="idsk-character-count"
                          data-maxlength="350"
                        >
                          <div className="govuk-form-group">
                            <label className="govuk-label" for="with-hint">
                              Popíšte chybu bližšie
                            </label>
                            <span
                              id="with-hint-hint"
                              className="govuk-hint"
                            ></span>
                            <textarea
                              className="govuk-textarea govuk-js-character-count "
                              id="with-hint"
                              name="with-hint"
                              rows="5"
                              aria-describedby="with-hint-info with-hint-hint"
                            ></textarea>
                          </div>

                          <span
                            id="with-hint-info"
                            className="govuk-hint govuk-character-count__message"
                            aria-live="polite"
                          >
                            You can enter up to 350 characters
                          </span>
                        </div>
                      </div>
                      <div
                        className="idsk-footer-extended__feedback-info"
                        data-email="spatnavazba@sazp.sk"
                        data-subject="Nahlásenie chyby"
                      >
                        Dobrý deň, na stránke data.sazp.sk máte problém týkajúci
                        sa: %issue%. Popis chyby: %description%
                      </div>
                      <div className="idsk-footer-extended-feedback-button">
                        <button
                          id="submit-button-error-form"
                          type="submit"
                          className="idsk-button"
                          data-module="idsk-button"
                        >
                          Odoslať
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="idsk-footer-extended-main-content">
            <div className="govuk-width-container">
              <div className="govuk-grid-row">
                <div className="govuk-grid-column-one-third-from-desktop idsk-footer-extended-logo-box">
                  <div className="idsk-footer-extended-logo">
                    <img src="/assets/images/sazp_black.svg" alt="logo SAZP" />
                  </div>
                </div>
                <div className="govuk-grid-column-one-third-from-desktop">
                  <p className="idsk-footer-extended-frame">
                    <a className="govuk-link" title="Kontakt" href="/kontakt">
                      Kontakt
                    </a>
                    <br />
                    <a
                      className="govuk-link"
                      title="Vyhlásenie o prístupnosti"
                      href="/vyhlasenie-o-pristupnosti"
                    >
                      Vyhlásenie o prístupnosti
                    </a>
                  </p>
                </div>
                <div className="govuk-grid-column-one-third-from-desktop">
                  <p className="idsk-footer-extended-frame">
                    Prevádzkovateľom služby je Slovenská agentúra životného
                    prostredia.
                    <br />
                    Vytvorené v súlade s{" "}
                    <a
                      className="govuk-link"
                      title="Jednotným dizajn manuálom elektronických služieb."
                      href="https://idsk.gov.sk/"
                    >
                      Jednotným dizajn manuálom elektronických služieb.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
