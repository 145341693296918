import React, { useEffect } from "react";
import styles from "./Page.module.scss";
import {
  pageData,
  getPageData,
  ready,
  errorFetchingData,
  error,
} from "../store/slices/pageSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import SourcePage from "./SourcePage";
import ServicePage from "./ServicePage";
import Breadcrumbs from "../components/Breadcrumbs";
import Hlavicka from "../components/Hlavicka";
import Footer from "../components/Footer";

const Page = () => {
  const dispatch = useDispatch();
  const errorMsg = useSelector(error);
  const data = useSelector(pageData);
  const isErrorFetchingData = useSelector(errorFetchingData);
  const isReady = useSelector(ready);

  const location = useLocation();

  useEffect(() => {
    let urlWithoutFirstSlash = location.pathname.substring(1);

    if (urlWithoutFirstSlash.endsWith("/")) {
      urlWithoutFirstSlash = urlWithoutFirstSlash.slice(0, -1);
    }
    const numberOfOccurrences = urlWithoutFirstSlash.split("/").length - 1;

    let newUrl = urlWithoutFirstSlash.replace(/\//g, "_");

    if (numberOfOccurrences === 0) {
      dispatch(getPageData("/sources/" + newUrl));
    }
    if (numberOfOccurrences > 0) {
      dispatch(getPageData("/services/" + newUrl));
    }
  }, [location, dispatch]);

  return isReady && data && !isErrorFetchingData ? (
    <>
      <Hlavicka title={"DÁTA SAŽP"} subtitle={"Portál otvorených dát"} />
      <div className="govuk-width-container">
        <div className={styles["page"]}>
          <Breadcrumbs />
          {data["@type"] === "Source" ? <SourcePage data={data} /> : null}
          {data["@type"] === "Service" ? <ServicePage data={data} /> : null}
        </div>
      </div>
      <Footer />
    </>
  ) : isErrorFetchingData ? (
    <div className="govuk-width-container">
      <div className={styles["page-error"]}>
        <h2>CHYBA</h2>
        <p>Stavový kód:</p>
        <h2>{errorMsg}</h2>
      </div>
    </div>
  ) : null;
};

export default Page;
