import parse from "html-react-parser";

const renderHTML = (html) => {
  if (!html) return null;

  const style = {
    backgroundColor: "inherit",
    maxWidth: "100%",
    overflowWrap: "break-word",
    wordWrap: "break-word",
  };

  return <div style={style}>{parse(html)}</div>;
};

export default renderHTML;
