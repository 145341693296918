import React from "react";
import styles from "./WebService.module.scss";
import renderHTML from "../helpers/renderHTML";
import Dataset from "./Dataset";

const WebService = ({ webService }) => {
  const { name, description, datasets } = webService;

  return (
    <>
      <div className={`${styles["title-black"]}`}>
        <h2 className="govuk-heading-s">{name}</h2>
      </div>
      <div className={styles["row-content"]}>
        <div className="govuk-body-s">{renderHTML(description)}</div>
      </div>
      <section className={styles["row-content"]}>
        <h2 className="govuk-heading-s">
          Zoznam a popis datasetov publikovaných službou
        </h2>
      </section>
      {Object.values(datasets)?.map((dataset, index) => (
        <div className={styles["dataset-wrapper"]} key={`dataset_${index}`}>
          <Dataset
            name={dataset.name}
            description={dataset.description}
            serviceType={dataset.serviceType}
            items={dataset.items}
            index={index}
          />
        </div>
      ))}
    </>
  );
};

export default WebService;
