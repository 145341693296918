import React, { useEffect, useState } from "react";
import SearchPanel from "../components/SearchPanel";
import Source from "../components/Source";

import VyhladavanieBox from "../components/VyhladavanieBox";
import styles from "./SearchPage.module.scss";

import { useDispatch, useSelector } from "react-redux";
import {
  sourcesData,
  keywordOptionsData,
  protocolOptionsData,
  selectedKeywordsData,
  selectedProtocolsData,
  setUniqueKeywords,
  setUniqueProtocols,
  getSources,
  searchTermStr,
  ready,
} from "../store/slices/appSlice";
import Hlavicka from "../components/Hlavicka";
import Footer from "../components/Footer";

const Search = () => {
  const dispatch = useDispatch();
  const [filteredApis, setFilteredApis] = useState([]);

  const data = useSelector(sourcesData);
  const isReady = useSelector(ready);
  const keywordOptions = useSelector(keywordOptionsData);
  const protocolOptions = useSelector(protocolOptionsData);
  const selectedKeywords = useSelector(selectedKeywordsData);
  const selectedProtocols = useSelector(selectedProtocolsData);
  const searchInput = useSelector(searchTermStr);

  useEffect(() => {
    if (!isReady) {
      dispatch(getSources());
    }
  }, [dispatch, isReady]);

  useEffect(() => {
    if (isReady) {
      dispatch(setUniqueKeywords(data));
      dispatch(setUniqueProtocols(data));
    }
  }, [dispatch, isReady, data]);

  useEffect(() => {
    setFilteredApis(
      filterData(data, selectedKeywords, selectedProtocols, searchInput)
    );
  }, [selectedKeywords, selectedProtocols, data, searchInput]);

  const filterData = (
    data,
    selectedKeywords,
    selectedProtocols,
    searchInput
  ) => {
    const filteredData = data.filter((api) => {
      const hasSelectedKeywords =
        selectedKeywords.length === 0 ||
        selectedKeywords.every((keyword) => {
          return api.keywords?.some((obj) => obj.name === keyword);
        });

      const protocolsArr = api?.protocols?.map((protocol) => {
        return protocol.name;
      });

      const hasSelectedProtocols =
        selectedProtocols.length === 0 ||
        selectedProtocols.every((protocol) => {
          return protocolsArr?.includes(protocol);
        });

      const normalizedSearchString = searchInput
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      const normalizedApiName = api.name
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      const hasSearchString =
        normalizedSearchString === "" ||
        normalizedApiName
          .toLowerCase()
          .includes(normalizedSearchString.toLowerCase());

      return hasSelectedKeywords && hasSelectedProtocols && hasSearchString;
    });
    return filteredData;
  };

  return isReady && keywordOptions && protocolOptions ? (
    <>
      <Hlavicka title={"DÁTA SAŽP"} subtitle={"Portál otvorených dát"} />
      <div className="govuk-width-container">
        <div className={styles["search"]}>
          <h2 className={styles["vyhladavanie"]}>Vyhľadávanie</h2>
          <VyhladavanieBox />
          <div className={styles["search-main"]}>
            <div></div>
            <h2 className={styles["visually-hidden"]}>
              Zoznam aplikačných služieb:
            </h2>
            {
              <div>
                <SearchPanel
                  keywordOptions={keywordOptions}
                  protocolOptions={protocolOptions}
                />
              </div>
            }
            <div>
              {searchInput ? (
                <h2 className="govuk-body " style={{ lineHeight: "2rem" }}>
                  Výsledky vyhľadávania výrazu “
                  <span>
                    <strong>{searchInput}</strong>
                  </span>
                  ”
                </h2>
              ) : null}
              {filteredApis &&
                filteredApis.length > 0 &&
                filteredApis.map((item, index) => {
                  return <Source data={item} key={item.name + "_" + index} />;
                })}
              <Source />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  ) : null;
};

export default Search;
