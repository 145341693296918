import React, { useEffect } from "react";
import styles from "./ContactPage.module.scss";
import Footer from "../components/Footer";
import Hlavicka from "../components/Hlavicka";

const ContactPage = () => {
  useEffect(() => {
    document.title = "Kontakt | Dáta SAŽP";
  }, []);

  return (
    <>
      <Hlavicka title={"DÁTA SAŽP"} subtitle={"Portál otvorených dát"} />
      <div className="govuk-width-container">
        <div className={styles.wrapper}>
          <h1>KONTAKT</h1>
          <br />
          <p className="govuk-body-s">
            <strong>
              Prevádzkovateľ webového sídla{" "}
              <a href="https://data.sazp.sk/" className="govuk-link">
                https://data.sazp.sk/
              </a>
            </strong>
          </p>
          <div className="govuk-body-s">
            Slovenská agentúra životného prostredia
            <br />
            Sekcia environmentálnej informatiky
            <br />
            Tajovského 28
            <br />
            975 90 Banská Bystrica
            <br />
          </div>
          <strong>
            <a href="mailto:data@sazp.sk" className="govuk-link">
              data@sazp.sk
            </a>
          </strong>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
