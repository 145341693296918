import React, { useEffect } from "react";
import { imgUrl } from "../common/config";
import renderHTML from "../helpers/renderHTML";
import styles from "./Service.module.scss";
import { useLocation } from "react-router-dom";

const Service = ({ data }) => {
  const location = useLocation();
  const urlWithoutFirstSlash = location.pathname.substring(1);

  useEffect(() => {
    document.title = data.name || "Dáta SAŽP";
  }, [data.name]);

  return (
    <div className={styles["sluzba-karta-wrapper"]}>
      <div className={styles["row-1"]}>
        <div className={styles["logo"]}>
          {data.icons ? (
            <a
              href={
                urlWithoutFirstSlash.endsWith("/")
                  ? data.seoId
                  : urlWithoutFirstSlash + "/" + data.seoId
              }
            >
              <img src={imgUrl + data.icons["200"]} alt="" />
            </a>
          ) : null}
        </div>
        <div>
          <h2 className="govuk-heading-m">{data.name}</h2>
          <div className="govuk-body">{renderHTML(data.firstLevel)}</div>
        </div>
      </div>
      <div className={styles["row-2"]}>
        <div></div>
        <div className={styles["row-2-right"]}>
          <div className={styles["row-2-box"]}>
            <h3 className="govuk-heading-s">
              Informácie sprístupnené rozhraním ako:
            </h3>
            <p className="govuk-body">
              dataset s otvorenými priestorovými údajmi
            </p>
          </div>
          <div className={styles["klucove-slova-wrapper"]}>
            <h3 className="govuk-heading-s">Kľúčové slová:</h3>
            {Object.keys(data.firstLevelKeywords)?.map((key) => {
              return (
                <p
                  className={styles["klucove-slova"]}
                  key={data.firstLevelKeywords[key].seoId ?? ""}
                >
                  {data.firstLevelKeywords[key].name ?? ""}
                </p>
              );
            })}
          </div>
          <div className={styles["row-2-box"]}>
            <h3 className="govuk-heading-s">Stav aktualizácie:</h3>
            <p className="govuk-body">
              Údaje poskytovaných datasetov sú aktualizované priamo z databázy
              zdrojovej evidencie v reálnom čase.
            </p>
          </div>
        </div>
        <div></div>
        <div className={styles["button-wrapper"]}>
          <a
            href={
              urlWithoutFirstSlash.endsWith("/")
                ? data.seoId
                : urlWithoutFirstSlash + "/" + data.seoId
            }
            className={styles["button"]}
          >
            Zobraziť služby
          </a>
        </div>
      </div>
    </div>
  );
};

export default Service;
