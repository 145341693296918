import React from "react";
import styles from "./Hlavicka.module.scss";

const Hlavicka = ({ title }) => {
  return (
    <header className="idsk-header-web " data-module="idsk-header-web">
      <div className="idsk-header-web__scrolling-wrapper">
        <div className="idsk-header-web__tricolor"></div>
        <div className="idsk-header-web__brand ">
          <div className="govuk-width-container">
            <div className="govuk-grid-row">
              <div className="govuk-grid-column-full">
                <div className="idsk-header-web__brand-gestor"></div>
                <br />
              </div>
            </div>
          </div>
        </div>

        <div className="idsk-header-web__main">
          <div className="govuk-width-container">
            <div className="govuk-grid-row">
              <div className={styles["title"]}>
                <div className="idsk-header-web__main-headline">
                  <a href="/" title="Odkaz na úvodnú stránku">
                    <h2 className="govuk-heading-m govuk-grid-column-full">
                      {title}
                    </h2>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Hlavicka;
