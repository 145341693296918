import React, { useEffect } from "react";
import styles from "./AccessPage.module.scss";
import Footer from "../components/Footer";
import Hlavicka from "../components/Hlavicka";

const AccessPage = () => {
  useEffect(() => {
    document.title = "Vyhlásenie o prístupnosti | Dáta SAŽP";
  }, []);

  return (
    <>
      <Hlavicka title={"DÁTA SAŽP"} subtitle={"Portál otvorených dát"} />
      <div className="govuk-width-container">
        <div className={styles.wrapper}>
          <h1>Vyhlásenie o prístupnosti</h1>
          <p>
            Slovenská agentúra životného prostredia má záujem zabezpečiť
            prístupnosť svojho webového sídla v súlade so zákonom č. 95/2019 Z.
            z. o informačných technológiách vo verejnej správe a o zmene a
            doplnení niektorých zákonov v znení neskorších predpisov a
            príslušnými vykonávacími predpismi.
          </p>

          <p>
            Toto vyhlásenie o prístupnosti sa vzťahuje na webové sídlo{" "}
            <a href="https://data.sazp.sk/">https://data.sazp.sk/</a>.
          </p>

          <h2>Stav súladu</h2>
          <p>
            Toto webové sídlo je v čiastočnom súlade so zákonom č. 95/2019 Z. z.
            o informačných technológiách vo verejnej správe a o zmene a doplnení
            niektorých zákonov v znení neskorších predpisov a príslušnými
            vykonávacími predpismi vzhľadom na vzhľadom na prvky nesúladu
            uvedené nižšie.
          </p>

          <h2>Neprístupný obsah</h2>
          <p>Obsah uvedený nižšie nie je prístupný z týchto dôvodov:</p>

          <ul>
            <li>
              nesúlad s pravidlami prístupnosti podľa § 14 vyhlášky o
              štandardoch pre ITVS:
              <ul>
                <li>
                  Niektoré obrázky na webových stránkach nie sú doplnené o
                  alternatívne texty. [Kritérium úspešnosti 1.1.1 Netextový
                  obsah]
                </li>
                <li>
                  Niektoré nadpisy nie sú správne hierarchicky vnorené. Niektoré
                  zoznamy nepoužívajú sémantické značky [Kritérium úspešnosti
                  1.3.1 Informácie a vzájomné vzťahy]
                </li>
                <li>
                  Niektoré hypertextové odkazy používajú farbu ako jediný
                  rozlišovací prostriedok od bežného textu [Kritérium úspešnosti
                  1.4.1 Používanie farieb]
                </li>
                <li>
                  Vo výsledkoch vyhľadávania sa používa farba textu, ktorá
                  nespĺňa minimálny požadovaný farebný kontrast [Kritérium
                  úspešnosti 1.4.3 Kontrast (minimálny)]
                </li>
                <li>
                  Webové stránky nemajú implementovaný skip-link [Kritérium
                  úspešnosti 2.4.1 Preskočenie blokov]
                </li>
                <li>
                  Webové stránky používajú rovnaký názov. [Kritérium úspešnosti
                  2.4.2 Každá stránka má názov]
                </li>
                <li>
                  Na webovej stránke sa nachádzajú niektoré hypertextové odkazy,
                  ktoré nie sú prepojené s ich kontextom. [Kritérium úspešnosti
                  2.4.4 Účel odkazu (v kontexte)]
                </li>
                <li>
                  Webové stránky, ktoré sú zverejnené v slovenskom jazyku majú
                  nastavený hlavný jazyk na angličtinu. [Kritérium úspešnosti
                  3.1.1 Jazyk stránky]
                </li>
                <li>
                  Niektoré časti webového obsahu, ktoré sú vytvorené v inom
                  jazyku ako je hlavný jazyk webovej stránky, nemajú v HTML kóde
                  nastavený jazyk. [Kritérium úspešnosti 3.1.2 Jazyk
                  jednotlivých častí]
                </li>
                <li>
                  Na webovej stránke sa vyskytujú chýbajúce párové značky a
                  syntaktické chyby. [Kritérium úspešnosti 4.1.1 Syntaktická
                  analýza]
                </li>
                <li>
                  Vyhľadávacie vstupné pole nemá vytvorenú menovku. Niektoré
                  akordeóny nedokážu informovať o otvorenom a zatvorenom stave.
                  Niektoré ARIA atribúty nemajú platné referencie [Kritérium
                  úspešnosti 4.1.2 Názov, rola, hodnota]
                </li>
              </ul>
            </li>
          </ul>
          <h2>Vypracovanie tohto vyhlásenia o prístupnosti</h2>

          <p>Toto vyhlásenie bolo vypracované 28. 12. 2023.</p>

          <p>
            Vyhodnotenie súladu webového sídla s požiadavkami zákona č. 95/2019
            Z. z. o informačných technológiách vo verejnej správe a o zmene a
            doplnení niektorých zákonov v znení neskorších predpisov a
            príslušnými vykonávacími predpismi bolo vykonané samohodnotením.
          </p>

          <p>Vyhlásenie bolo naposledy skontrolované 28. 12. 2023.</p>

          <h2>Spätná väzba a kontaktné informácie</h2>
          <p>
            V prípade, že sa na našom webovom sídle vyskytuje obsah, ktorý nie
            je v súlade s pravidlami prístupnosti podľa § 14 ods. 1 vyhlášky o
            štandardoch pre ITVS, oznámte nám to, prosím na e-mailovej adrese:{" "}
            <a href="http://data@sazp.sk">data@sazp.sk.</a>
          </p>

          <h2>Vynucovacie konanie</h2>

          <p>
            V prípade neuspokojivej odpovede na oznámenie nesúladu webového
            sídla/mobilnej aplikácie s pravidlami prístupnosti podľa § 14
            vyhlášky o štandardoch pre ITVS v rámci mechanizmu spätnej väzby
            alebo v prípade neuspokojivej odpovede na žiadosť o informáciu alebo
            obsah, ktorý je vyňatý z rozsahu pôsobnosti zákona o ITVS v rámci
            mechanizmu spätnej väzby, sa môžete obrátiť na Ministerstvo
            investícií, regionálneho rozvoja a informatizácie Slovenskej
            republiky, ako na subjekt poverený presadzovaním Smernice (EÚ)
            2016/2102, na adrese:
          </p>

          <p>
            Ministerstvo investícií, regionálneho rozvoja a informatizácie
            Slovenskej republiky
            <br />
            Pribinova 4195/25
            <br />
            811 09 Bratislava
            <br />
          </p>
          <p>
            alebo na e-mailovej adrese:{" "}
            <a href="mailto:standard@mirri.gov.sk">standard@mirri.gov.sk</a>.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AccessPage;
