import React from "react";
import { imgUrl } from "../common/config";
import renderHTML from "../helpers/renderHTML";

// A reusable component for rendering each license
const Licence = ({ license }) => {
  const { name, icons, description } = license;
  return (
    <React.Fragment>
      <img src={imgUrl + icons["50"]} alt={name} />
      <h3 className="govuk-body-s">{name}</h3>
      <div className="govuk-body-s">{renderHTML(description)}</div>
    </React.Fragment>
  );
};

export default Licence;
